<template>
  <div>
    <label style="line-height: 40px">За период</label>
    <el-select
      v-model="query.period_ids"
      placeholder="Выберите период"
      style="margin-left: 20px"
      multiple
      @change="fetchData">
      <el-option
        v-for="item in periods"
        :key="item.id"
        :label="item.title"
        :value="item.id">
      </el-option>
    </el-select>
    <el-checkbox
      v-model="query.null_values"
      style="float: right; line-height: 40px"
      @change="fetchData">
      Данные по КПР без рейтинга
    </el-checkbox>
    <el-divider/>
    <el-row>
      <el-col :span="12">
        <div  class="length-text">
          <span>Показано {{ list.length }} {{ getSpell(list.length)}} из {{ query.total }}</span>
        </div>
      </el-col>
    </el-row>
    <el-table
      v-if="showTable"
      v-loading="loading"
      ref="table"
      :data="list"
      border
      fit
      size="mini"
      class="main-table">
      <el-table-column>
        <el-table-column
          label="ИОГВ"
          align="center"
          prop="short_name"
          label-class-name="order">
          <el-table-column
            prop="short_name"
            width="130px"
            :sort-orders="[]">
            <template slot="header">
              <el-select
                v-model="query.ebosp_ids"
                size="mini"
                filterable
                clearable
                style="width: 100%"
                multiple
                @change="fetchData()">
                <el-option
                  v-for="(item) in iogvs"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id" />
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="Показатель"
          prop="name"
          align="center"
          label-class-name="order">
          <el-table-column
            prop="name"
            width="300px"
            :sort-orders="[]">
          </el-table-column>
        </el-table-column>
      </el-table-column>
      <el-table-column
        v-for="id in query.period_ids"
        :key="id"
        align="center"
        :label="getPeriodName(id)"
        width="300px">
        <el-table-column
          label="Текущее значение"
          align="center"
          label-class-name="order">
          <el-table-column
            :prop="`per_${id}_result`"
            width="100px"
            :sort-orders="[]">
            <template slot="header">
              <v-input
                v-model="query.results[id]"
                @change="fetchData()" />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="Количество баллов"
          align="center"
          label-class-name="order">
          <el-table-column
            :prop="`per_${id}_rating`"
            width="100px"
            :sort-orders="[]">
            <template slot="header">
              <v-input
                v-model="query.ratings[id]"
                @change="fetchData()" />
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="Место в рейтинге"
          align="center"
          label-class-name="order">
          <el-table-column
            :prop="`per_${id}_place`"
            width="100px"
            :sort-orders="[]">
            <template slot="header">
              <v-input
                v-model="query.places[id]"
                @change="fetchData()" />
            </template>
          </el-table-column>
        </el-table-column>
      </el-table-column>
    </el-table>
    <pagination
      v-show="query.total>0"
      :auto-scroll="true"
      :limit.sync="query.per_page"
      :page.sync="query.current_page"
      :total="query.total"
      @pagination="fetchData" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import VInput from '@/components/VInput'
import userCan from '@/utils/checkPermission'

export default {
  components: { Pagination, VInput },
  props: {
    name: {
      type: String,
      required: true
    },
    fetch: {
      type: Function,
      required: true
    },
    periods: {
      type: Array,
      required: true
    },
    iogvs: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      showTable: true,
      loading: false,
      list: [],
      query: {
        period_ids: [],
        ebosp_ids: [],
        results: [],
        ratings: [],
        places: [],
        null_values: false,
        total: 0,
        filter: undefined,
        current_page: 1,
        per_page: 20,
        sort_column: undefined,
        sort_direction: undefined
      }
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    userCan,
    getPeriodName (id) {
      const period = this.periods.find(x => x.id === id)
      return period?.title
    },
    fetchData () {
      this.loading = true
      return this.fetch(this.query).then(({ data }) => {
        this.list = data.data
        this.query.total = data.meta.total
        this.query.period_ids = data.meta.searchModel.period_ids
        // force re-render for select in filter (status for example)
        this.showTable = false
        this.$nextTick(() => {
          this.showTable = true
        })
      }).finally(() => {
        this.loading = false
      })
    },
    getSpell (val) {
      if (val % 100 < 20 && val % 100 > 10) {
        return 'записей'
      }
      if (val % 10 === 1) {
        return 'запись'
      } else if (val % 10 < 5 && val % 10 > 1) {
        return 'записи'
      }
      return 'записей'
    }
  }
}
</script>

<style scoped>

.main-table >>> .el-table__header-wrapper {
  /* need for sticky header */
  top: 50px;
  z-index: 1;
}

.el-table >>> .cell {
  line-height: 17px;
}

.el-table >>> .el-input__suffix {
  right: 10px;
}
</style>
