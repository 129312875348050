<template>
  <div style="margin: 20px">
    <div>
        <monitoring-table
          :iogvs="iogv"
          :periods="periods"
          name="monitoring"
          :fetch="fetchData"/>
      </div>
  </div>
</template>

<script>
import MonitoringTable from '@/components/MonitoringTable'
import { getMonitoring } from '@/api/api'
import { getIogvDict, getPeriods } from '@/api/catalogs'
import userCan from '@/utils/checkPermission'

export default {
  components: {
    MonitoringTable
  },
  data () {
    return {
      params: {
        period_ids: null,
        null_values: null
      },
      periods: [],
      iogv: []
    }
  },
  beforeMount () {
    getPeriods({ is_monthly: null }).then((response) => {
      this.periods = response.data.data
    })
    getIogvDict({ in_rating: true }).then(response => {
      this.iogv = response.data.data
    })
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    checkboxChange (val) {
      this.params.need_ebosp_comment = val
      this.fetchData()
    },
    userCan: (roles) => userCan(roles),
    fetchData (params) {
      return getMonitoring(params)
    }
  }
}

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
